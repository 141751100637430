<template>
  <!-- 拣货任务 -->
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="120px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="收货单号">
              <el-input v-model="form.receiptId" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="关联单号">
              <el-input v-model="form.orderId" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库类型">
              <el-select v-model="form.depositType" :placeholder="$t('page.selectPlaceholder')">
                <el-option v-for="item in getDictionariesOpt('VWMS_DEPOSIT_TYPE')" :key="item.val" :value="item.val" :label="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓库">
              <el-select v-model="form.logicWarehouseCode" filterable clearable :placeholder="$t('page.selectPlaceholder')">
                <el-option
                  v-for="item in warehouseList"
                  :key="item.warehouseCode"
                  :value="item.logicWarehouseCode"
                  :label="item.logicWarehouseName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="创建日期">
              <el-date-picker
                v-model="form.date"
                type="daterange"
                value-format="yyyy-MM-dd "
                range-separator="~"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              />
              <!-- <el-date-picker
                v-model="form.date"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="~"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :unlink-panels="true"
                :default-time="['00:00:00', '23:59:59']"
              /> -->
            </el-form-item>
          </el-col>

        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-row class="mb-2">
      <el-col>
        <el-button type="primary" @click="completeCollectTask()">收货完成</el-button>
      </el-col>
    </el-row>

    <el-table
      ref="tableData"
      v-loading="tableLoading"
      class="mb-3"
      :data="tableData"
      :header-cell-style="{background:'#fafafa'}"
      max-height="550px"
      @select="changSelect"
      @selection-change="val=>multipleSelectedTable=val"
    >
      <!-- @select-all="changSelectAll"  -->
      <el-table-column type="selection" :selectable="selectable" width="50" />
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="trayId" label="入库单号" width="180" align="center" /> -->
      <el-table-column prop="receiptId" label="收货单号" width="190" align="center">
        <template slot-scope="scope">
          <router-link :to="{ append:true, path: 'details', query: {receiptId: scope.row.receiptId, tableSuffix: scope.row.tableSuffix, receiptData: JSON.stringify(scope.row)}}" style="color:#1890ff;">{{ scope.row.receiptId }}
          </router-link></template>
      </el-table-column>
      <el-table-column prop="orderId" label="关联单号" width="190" align="center">
        <template slot-scope="scope">{{ scope.row.orderId }}</template>
      </el-table-column>
      <el-table-column prop="transportId" label="运输单号" width="190" align="center">
        <template slot-scope="scope">{{ scope.row.transportId }}</template>
      </el-table-column>
      <el-table-column prop="logicWarehouseName" label="仓库" align="center" width="110" />
      <el-table-column prop="depositType" label="入库类型" align="center" width="110">
        <template slot-scope="scope">{{ getDepositLabel(scope.row.depositType) }}</template>
      </el-table-column>
      <el-table-column prop="depositType" label="入库方式" align="center" width="110">
        <template slot-scope="scope">{{ getReceiveTypeLabel(scope.row.receiveType) }}</template>
      </el-table-column>
      <el-table-column prop="isTransitDiff" label="是否有在途差异" align="center" width="120">
        <template slot-scope="scope">
          <p v-if="[1,'1'].includes(scope.row.isTransitDiff)">
            是
          </p>
          <p v-else-if="[0,'0'].includes(scope.row.isTransitDiff)">
            否
          </p>
          <p v-else />
        </template>
      </el-table-column>
      <el-table-column prop="receiveTime" label="收货时间" align="center" />
      <el-table-column prop="expectPairs" label="应收数量" align="center" />
      <el-table-column prop="actualPairs" label="已收数量" align="center" />
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="scope">{{ getCollectStatusLabels(scope.row.status) }}</template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center" />
      <el-table-column
        align="center"
        fixed="right"
        :label="$t('page.operate')"
        width="150"
      >
        <template slot-scope="scope">
          <!-- 查看 -->
          <span>
            <el-button type="text" size="small" class="can-copy" @click="goToDetails(scope.row)">查看</el-button>
          </span>
        </template>
      </el-table-column>

    </el-table>
    <Paging :pager="pager" @update="update" />
  </div>
</template>
<script>
import { queryWarehouseList, pageCollectTask, completeCollectTask } from '@/api/stockin'
import { Mixin } from '@/mixin/mixin.js'
import Paging from '@/components/Paging'
export default {
  components: {
    Paging
  },
  mixins: [Mixin],
  data() {
    return {
      form: {
        orderId: '',
        depositType: '',
        receiptId: '',
        logicWarehouseCode: '',
        date: []
      },
      multipleSelectedTable: [],
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      warehouseList: [],
      showRow: false, // 切换显示input
      tableLoading: false,
      tableData: [
      ]
    }
  },
  computed: {
    queryParams() {
      const [startCreateTime = '', endCreateTime = ''] = this.form.date || []
      const { receiptId, orderId, depositType, logicWarehouseCode } = this.form
      return Object.assign({}, this.pager, { startCreateTime: startCreateTime ? startCreateTime + '00:00:00' : startCreateTime, endCreateTime: endCreateTime ? endCreateTime + '23:59:59' : endCreateTime, receiptId, orderId, depositType, logicWarehouseCode })
    }
  },

  mounted() {
    this._pageCollectTask()
    this._queryWarehouseList()
  },
  methods: {

    async _queryWarehouseList() {
      const { datas } = await queryWarehouseList()
      this.warehouseList = datas
    },
    queryClick() { // 点击查询获取信息
      this.pager.current = 1
      this._pageCollectTask(1)
    },
    resetClick(val) { // 点击重置清空文本框信息
      Object.assign(this.form, this.$options.data.call(this).form)
      this._pageCollectTask(1)
    },
    showRowClick() { // 点击展开控制文本框的显示隐藏
      this.showRow = !this.showRow
    },
    async _pageCollectTask(flag) {
      try {
        this.tableLoading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { datas: { pager, records }} = await pageCollectTask(this.queryParams)
        this.pager = pager
        this.tableData = records
        this.tableLoading = false
      } finally {
        this.tableLoading = false
      }
    },
    update(val) {
      this.pager = val
      this._pageCollectTask()
    },
    changSelect(rows, row) {
      this.multipleSelectedTable = rows
      console.log(11111)
      console.log(rows, row)
      rows.map(e => {
        if ([3, '3'].includes(e.status)) {
          this.$refs.tableData.toggleRowSelection(e, false)
        }
      })
    },
    // 当前行是否可以选中
    selectable(row, index) {
      if (row.status === '3' || row.status === 3) {
        return false
      } else {
        return true
      }
    },
    // changSelectAll(val) {
    // if (val) {
    //   console.log(11111)
    //   console.log(val, row)
    //   val.map(e => {
    //     if ([3, '3'].includes(e.status)) {
    //       this.$refs.tableData.toggleRowSelection(e)
    //     }
    //   })
    // } else {
    //   this.$refs.tableData.clearSelection()
    // }
    // },
    completeCollectTask(row, index) {
      console.log(111)
      // const newDate = (new Date()).Format('yyyy-MM-dd hh:mm:ss')
      // js获取当前时间，并格式化为"yyyy-MM-dd HH:mm:ss"
      function getFormatDate() {
        var date = new Date()
        var month = date.getMonth() + 1
        var strDate = date.getDate()
        var strHours = date.getHours()
        var strMinutes = date.getMinutes()
        var strSeconds = date.getSeconds()
        if (month >= 1 && month <= 9) {
          month = '0' + month
        }
        if (strDate >= 0 && strDate <= 9) {
          strDate = '0' + strDate
        }
        if (strDate >= 0 && strDate <= 9) {
          strDate = '0' + strDate
        }
        if (strHours >= 0 && strHours <= 9) {
          strHours = '0' + strHours
        }
        if (strMinutes >= 0 && strMinutes <= 9) {
          strMinutes = '0' + strMinutes
        }
        if (strSeconds >= 0 && strSeconds <= 9) {
          strSeconds = '0' + strSeconds
        }

        var currentDate = date.getFullYear() + '-' + month + '-' + strDate +
        ' ' + strHours + ':' + strMinutes + ':' + strSeconds
        return currentDate
      }
      console.log(this.multipleSelectedTable)
      if (this.multipleSelectedTable.length > 0) {
        const receiptIds = []
        this.multipleSelectedTable.map(item => receiptIds.push(item.receiptId))
        if (this.multipleSelectedTable.some(item => item.receiveTime > getFormatDate())) {
          console.log(this.multipleSelectedTable)
          const reId = this.multipleSelectedTable.filter(i => i.receiveTime > getFormatDate())
          // const id = Array.from(reId, ({ receiptId }) => receiptId)
          const confirmText = Array.from(reId, ({ receiptId }) => receiptId)
          console.log(confirmText)
          const newDatas = []
          const h = this.$createElement
          for (const i in confirmText) {
            console.log(confirmText[i])
            newDatas.push(h('p', null, confirmText[i]))
          }
          console.log(newDatas)
          this.$confirm('提示', {
            title: '提示',
            message: h('p', null, [
              h('span', null, Array.from(reId, ({ receiptId }) => receiptId).join(',') + ' 的收货时间大于当前时间，是否继续操作?')
            ]),
            showCancelButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消'

          }).then(() => {
            console.log(receiptIds)
            this._completeCollectTask({ receiptIds })
          })
        } else {
          console.log(receiptIds)
          this._completeCollectTask({ receiptIds })
        }
        // if (this.multipleSelectedTable.some(item => item.receiveTime < getFormatDate() && ['1', '2'].includes(item.status))) {
        //   console.log(this.multipleSelectedTable.some(item => item))
        //   console.log(this.multipleSelectedTable.some(item => item.receiveTime > getFormatDate() && ['2', '3'].includes(!item.status)))
        //   this.multipleSelectedTable.map(item => receiptIds.push(item.receiptId))
        //   const { code, msg } = await completeCollectTask({ receiptIds })
        //   if (code === 0) {
        //     this.$notify({
        //       title: msg,
        //       message: msg,
        //       type: 'success'
        //     })
        //     this._pageCollectTask()
        //   }
        // } else {
        //   console.log(this.multipleSelectedTable.find(item => item))
        //   this.$message.warning('仅限【收货中】数据操作，请核对！')
        //   return
        // }
      } else {
        this.$message.warning('请至少选择一条数据')
      }
    },
    async _completeCollectTask(data) {
      const { code, msg } = await completeCollectTask(data)
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this._pageCollectTask()
      }
    },
    // 入库管理详情
    goToDetails(row) {
      this.$router.push({
        path: 'details',
        append: 'true',
        query: { 'receiptId': row.receiptId, 'tableSuffix': row.tableSuffix, receiptData: JSON.stringify(row) }
      })
    }
  }
}
</script>

